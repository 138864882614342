<template>
  <v-snackbar :timeout="duration"
              :color="type"
              :value="display"
              top center>
    {{ text }}
    <v-btn icon dark @click="$emit('toggle-notification')">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: "EventNotification",
  props: {
    duration: {
      type: Number,
      default: 4000,
    },
    type: {
      type: String,
      default: "info",
    },
    display: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>

</style>
